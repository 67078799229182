import {
  mdiHumanDolly,
  mdiReceiptTextOutline
  } from '@mdi/js'
  
  export default [
    {
      subheader: 'ADMIN RESOURCES',
      resource: 'po',
      action: 'manage',
    },
    {
      title: 'Suppliers',
      icon: mdiHumanDolly,
      to: 'suppliers-list',
      resource: 'po',
      action: 'manage',
    },
    {
      title: 'Purchase Orders',
      icon: mdiReceiptTextOutline,
      to: 'po',
      resource: 'po',
      action: 'manage',
    }
]
  