import axios from '@axios'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    isLoading: false
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    LOADING(state, value) {
      state.isLoading = value
    }
  },
  actions: {

    getUserAbilities(ctx, {email}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${email}/abilities/`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)})
      })
    },
  },
}
