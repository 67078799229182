import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import { MSALPlugin } from './plugins/auth/msal-plugin'
import { msalConfig, foundryRequest, loginRequest, graphConfig } from './config/authConfig';

Vue.config.productionTip = false

Vue.use(MSALPlugin, 
  {
    msalConfig,
    loginRequest,
    foundryRequest,
    graphConfig,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
}
);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdiSvg'
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
