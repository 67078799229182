
export default {
    namespaced: true,
    state: {
        message: '',
        type: 'default',
        types: {
            info: 'info',
            success: 'success',
            warning: 'warning',
            error: 'error',
            default: 'warning'
        },
    },
    getters: {},
    mutations: {
        SET_TYPE(state, val) {
          state.type = val
        },
        SET_MESSAGE(state, val) {
            state.message = val
          },
      },
    actions: {}
}