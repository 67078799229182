import {
    mdiRobotOutline,
    mdiCompost,
    mdiShimmer
    } from '@mdi/js'
    
    export default [
      {
        subheader: 'EXTERNAL',
        resource: 'forsta',
        action: 'read',
      },
      {
        title: 'ChatGPT',
        icon: mdiRobotOutline,
        to: 'chat',
        resource: 'chat',
        action: 'read',
      },
      {
        title: 'Forsta Tools',
        icon: mdiCompost,
        to: 'forsta',
        resource: 'forsta',
        action: 'read',
      },
      {
        title: 'Sage',
        icon: mdiShimmer,
        to: 'sage',
        resource: 'sage',
        action: 'read',
      },
  ]
    