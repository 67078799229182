import {
    mdiHubspot,
  } from '@mdi/js'
  
  export default [
    {
      subheader: 'SALES',
      resource: 'sales',
      action: 'read',
    },
    {
      title: 'Hubspot',
      icon: mdiHubspot,
      to: 'hubspot',
      resource: 'sales',
      action: 'read',
    },
]
  