import Vue from "vue";
import * as msal from "@azure/msal-browser";

/**
 *  Vue Instance Definition
 */

 let instance;
 export const getInstance = () => instance;

 export const useMSAL = ({
    onRedirectCallback = () =>
      window.history.replaceState({}, document.title, window.location.pathname),
    redirectUri = window.location.origin,
    ...pluginOptions
  }) => {
    if (instance) return instance;

    instance = new Vue({
        data() {
          return {
            client: null,
            isLoading: true,
            isAuthenticated: false,
            account: {},
            user:'',
            error: null,
          };
        },
        async created() {
            this.client = new msal.PublicClientApplication(pluginOptions.msalConfig)
            try {
                if (window.location.href.includes("code=") && window.location.href.includes("state=")){
                      
                    const { appState } = await this.client.handleRedirectCallback();

                    onRedirectCallback(appState);
                }
            } catch (error) {
                this.error = error;
            } finally {
                // this.isAuthenticated = await this.client.isAuthenticated();
                // this.user = await this.client.getUser();
                this.loadAccount()
                this.isLoading = false;
            }
        },
        methods: {
            async handleRedirectCallback() {
                this.isLoading = true;
                try {
                //   await this.client.handleRedirectCallback();
                //   this.user = await this.client.getUser();
                  this.isAuthenticated = true;
                } catch (error) {
                  this.error = error;
                } finally {
                  this.isLoading = false;
                }
            },
            async loadAccount() {
            
                const currentAccounts = this.client.getAllAccounts();
                if (currentAccounts === null) {
                    
                    this.isAuthenticated = false;
                    return;
                } else if (currentAccounts.length > 1) {
                    // Add choose account code here
                    console.warn("Multiple accounts detected.");
                } else if (currentAccounts.length === 1) {
                    this.account = currentAccounts[0];
                    this.isAuthenticated = true;
                }
            },
            async signIn() {
                try {
                    const loginResponse = await this.client
                        .loginPopup(pluginOptions.loginRequest)
                        .catch(error => {
                            console.error(error);
                        });
    
                    if (loginResponse){
                        this.loadAccount()
                        const token = await this.getTokenPopup(pluginOptions.foundryRequest)
                        if (token) {
                            this.isAuthenticated = true;
                            return loginResponse
                        }
                    }
                } catch (err) {
                    // handle error
                    if (err.errorMessage && err.errorMessage.indexOf("AADB2C90118") > -1) {
                        try {
                            const passwordResetResponse = await msalInstance.loginPopup({
                                scopes: pluginOptions.loginRequest,
                                authority: this.pluginOptions.passwordAuthority
                            });
                            this.isAuthenticated = !!passwordResetResponse.account;
                        } catch (passwordResetError) {
                            console.error(passwordResetError);
                        }
                    } else {
                        this.isAuthenticated = false;
                    }
                }
            },
      
            async signOut() {
                const logoutRequest = {
                    account: this.client.getAccountByUsername(this.account.username)
                };
                const signout = await this.client.logoutPopup(logoutRequest);
                if (signout) {
                    this.isAuthenticated = false;
                    return signout
                }
            },
            
           
    
            async getTokenPopup(request) {
                /**
                 * See here for more info on account retrieval:
                 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
                 */
                request.account = this.client.getAccountByUsername(this.account.username);
                const tokenSilent = await this.client.acquireTokenSilent(request).catch(error => {
                    console.warn("silent token client fails. acquiring token using redirect");
                    if (error instanceof msal.InteractionRequiredAuthError) {
                        // fallback to interaction when silent call fails
                        return this.client.acquireTokenRedirect(request).then(tokenResponse => {
                            if (request === pluginOptions.foundryRequest){
                                
                                localStorage.setItem('accessToken', `${tokenResponse.tokenType} ${tokenResponse.accessToken}`);
                            }
                            
                            return tokenResponse;
    
                        }).catch(error => {
                            console.error(error);
                        });
                    } else {
                        console.warn(error);
                    }
                });
                if (tokenSilent) {
                    if (request === pluginOptions.foundryRequest){
                        localStorage.setItem('accessToken', `${tokenSilent.tokenType} ${tokenSilent.accessToken}`);
                    }
                    return tokenSilent;
                }
            },
        }

    });
    return instance
}

/**
 *  Vue Plugin Definition
 */
export const MSALPlugin = {
    install(Vue, options) {
      Vue.prototype.$msal = useMSAL(options);
    },
  };