 import { mdiFrequentlyAskedQuestions } from '@mdi/js'

export default [
    {
        subheader: 'OTHER',
    },
    {
        title: 'FAQ',
        icon: mdiFrequentlyAskedQuestions,
        to: {name: 'faq'},
        
    },
]
