import {
  mdiOrderBoolDescendingVariant
  } from '@mdi/js'
  
  export default [
    {
      subheader: 'SURVEY',
      resource: 'survey',
      action: 'read',
    },
    {
      title: 'Survey Builder',
      icon: mdiOrderBoolDescendingVariant,
      to: {name: 'survey-view' },
      resource: 'survey',
      action: 'read',
    },
    
]
  