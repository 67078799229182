import projects from './projects'
import dashboard from './dashboard'
import sales from './sales'
import accounting from './accounting'
import surveys from './surveys'
import teams from './teams'
import externals from './externals'
import pages from './pages'
import academy from './academy'

// Array of sections
export default [...dashboard, ...sales, ...projects, ...surveys, ...teams, ...externals, ...accounting, ...academy]
