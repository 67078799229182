const pages = [
  
  
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'public'
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/knowledge-base',
    name: 'page-knowledge-base',
    component: () => import('@/views/pages/knowledge-base/KnowledgeBase.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pages/knowledge-base/:category',
    name: 'page-knowledge-base-category',
    component: () => import('@/views/pages/knowledge-base/KnowledgeBaseCategory.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'page-knowledge-base',
    },
  },
  {
    path: '/pages/knowledge-base/:category/:slug',
    name: 'page-knowledge-base-question',
    component: () => import('@/views/pages/knowledge-base/KnowledgeBaseQuestion.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'page-knowledge-base',
    },
  },
  
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/pages/faq/Faq.vue'),
    meta: {
      title: 'FAQ',
      layout: 'content',
      resource: 'public'
    },
  },
  
]

export default pages
