import {
  mdiAccountMultipleOutline,
} from '@mdi/js'


export default [
  {
    subheader: 'TEAMS',
    resource: 'user',
    action: 'update'
  },
  {
    title: 'Members',
    icon: mdiAccountMultipleOutline,
    to: {name: 'user-list'},
    resource: 'user',
    action: 'update',
  },
]
