<template>
  <div
    class="app-content-container mx-auto h-full"
    :class="{'app-content-container-boxed': appContentWidth === 'boxed'}"
  >
  <v-btn
      v-scroll="onScroll"
      v-show="showScrollTop"
      fab
      dark
      fixed
      bottom
      right
      small
      color="primary"
      @click="toTop"
    >
      <v-icon>{{ icons.mdiArrowUp  }}</v-icon>
    </v-btn>
    <slot></slot>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import alertStoreModule from '@/views/components/alert/alertStoreModule'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { getVuetify } from '@core/utils'
import {
  mdiArrowUp
} from '@mdi/js'

export default {
  setup() {

    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const ALERT_COMP_STORE_MODULE_NAME = 'comp-alert'

    // Register module
    if (!store.hasModule(ALERT_COMP_STORE_MODULE_NAME)) {
      store.registerModule(ALERT_COMP_STORE_MODULE_NAME, alertStoreModule)
      store.commit('comp-alert/SET_MESSAGE', {})
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ALERT_COMP_STORE_MODULE_NAME)) store.unregisterModule(ALERT_COMP_STORE_MODULE_NAME)
    })

    const { appContentWidth } = useAppConfig()
    
    const $vuetify = getVuetify()

    const showScrollTop = ref(false)
    const onScroll = (e) => {
        if (typeof window === 'undefined') return
        const top = window.scrollY ||   e.target.scrollTop || 0
        showScrollTop.value = top > 20
    }
    const toTop = () => {
        $vuetify.goTo(0)
    }


    return {
      appContentWidth,
      onScroll,
      toTop,
      showScrollTop,

      icons: {
        mdiArrowUp
      }
    }
  },
}
</script>

<style lang="scss">
@import '~@core/layouts/styles/_variables';

.app-content-container {
  &.app-content-container-boxed {
    max-width: $content-max-width;
  }
}
</style>
