import { mdiChartDonut } from '@mdi/js'

export default [
  {
    title: 'Dashboards',
    icon: mdiChartDonut,
    children: [
      {
        title: 'Sales & Revenue',
        to: 'dashboard-sales',
        resource: 'dashboard',
        action: 'read',
      },
      {
        title: 'CSAT',
        to: 'dashboard-csat',
        resource: 'dashboard',
        action: 'read',
      },
      {
        title: 'Hubspot Dash',
        to: 'dashboard-hub',
        resource: 'dashboard',
        action: 'read',
      },
      {
        title: 'Academy',
        to: 'dashboard-academy',
        resource: 'academy',
        action: 'update',
      },
    ],
  },
]
