import { canNavigate } from '@/plugins/acl/routeProtection'
import ability from '@/plugins/acl/ability'
import Vue from 'vue'
import VueRouter from 'vue-router'
import apps from './apps'
import dashboard from './dashboard'
import pages from './pages'
import store from '@/store'


Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('user') || '{}')
      const userRole = userData && userData.role ? userData.role.name : null
      const isNotUser = userData && Object.keys(userData).length === 0 && Object.getPrototypeOf(userData) === Object.prototype
      
      if (['Director'].includes(userRole)) return  { name: 'dashboard-sales' }
      else if (isNotUser) return { name: 'auth-login', query: to.query }
      else  return  { name: 'project-list' }
      // if (userRole) return { name: 'my-board', params: {id: userData.email} }
      
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      title: '404',
      layout: 'blank',
      resource: 'public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'Login',
      layout: 'blank',
      resource: 'public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/logout',
    redirect: to => {
      
      
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('user')
      localStorage.removeItem('userAbility')
      localStorage.removeItem('timeline-filter')
      localStorage.removeItem('project-filter')
      
      
      return { name: 'auth-login', query: to.query }
      
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'public',
      redirectIfLoggedIn: true,
    },
  },
  ...dashboard,
  ...apps,
  ...pages,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  
  const userData = JSON.parse(localStorage.getItem('user') || '{}')

  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')
  
  store.dispatch('app/getUserAbilities', userData).then((response) => {
    const {data} = response
    ability.update(data.user_ability)
    localStorage.setItem('userAbility', JSON.stringify(data.user_ability))
  })
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  let title = 'LH - ' + to.meta.title;

  if (to.params){
    const keys = Object.keys(to.params);
    if (keys.length) {
      title = `LH - ${to.meta.title}: ${to.params[keys[0]]}`;
      if (to.params[keys[1]]) {
        title += ` ${to.params[keys[1]]}`;
      }
    }
  }
  document.title =  title || 'LH - PRS IN VIVO Projects'

  return next()
})

export default router
