// axios
import Vue from 'vue'
import axios from 'axios'
import { cacheAdapterEnhancer } from 'axios-extensions';

const throttleConfig = {
  threshold: 6*60*60*1000, // 6 hours on GET (project parameters don't change)
  enabledByDefault: false 
}

const cacheConfig = {
  enabledByDefault: false, 
  cacheFlag: 'useCache'
}

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 240000, // indicates, 90000ms ie. 90 second
  headers: {
      'Cache-Control': 'no-cache',
      "Content-Type": "application/json",
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, cacheConfig)
})

httpClient.interceptors.request.use(
  async (config) => {

    // Do something before request is sent
    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = httpClient

export default httpClient
