import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
      clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${process.env.VUE_APP_MSAL_TENANT_ID}`,
      redirectUri: process.env.VUE_APP_MSAL_REDIRECT,
      postLogoutRedirectUri:  process.env.VUE_APP_MSAL_REDIRECT,
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    // system: {
    //     loggerOptions: {
    //         loggerCallback: (level, message, containsPii) => {
    //             if (containsPii) {
    //                 return;
    //             }
    //             switch (level) {
    //                 case LogLevel.Error:
    //                     console.error(message);
    //                     return;
    //                 case LogLevel.Info:
    //                     console.info(message);
    //                     return;
    //                 case LogLevel.Verbose:
    //                     console.debug(message);
    //                     return;
    //                 case LogLevel.Warning:
    //                     console.warn(message);
    //                     return;
    //             }
    //         }
    //     }
    // }
  };
  
export const msalInstance = new PublicClientApplication(msalConfig);

  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
  };

// Add scopes here for ID token to be used at Microsoft identity platform Foudry endpoints.
export const foundryRequest = {
    scopes: [process.env.VUE_APP_FOUNDRY_SCOPE]
   };
  

export const graphConfig = {
    foundryMe: `${process.env.VUE_APP_BASE_URL}/users/profiles/me/`,
    graph: 'https://graph.microsoft.com/v1.0'
};