import { mdiSchoolOutline } from '@mdi/js'

export default [{
    subheader: 'ACADEMY',
    resource: 'academy',
    action: 'read',
  },
  {
    title: 'Courses',
    icon: mdiSchoolOutline,
    to: 'academy-course-list',
    resource: 'academy',
    action: 'read',
  },
]
