const dashboard = [
  
  {
    path: '/dashboards/sales',
    name: 'dashboard-sales',
    component: () => import('@/views/dashboards/sales/SalesRevenueView.vue'),
    meta: {
      title: 'Dashboard Sales',
      layout: 'content',
      resource: 'dashboard',
      action: 'read',
    },
  },
  {
    path: '/dashboards/csat',
    name: 'dashboard-csat',
    component: () => import('@/views/dashboards/csat/CSATDashboard.vue'),
    meta: {
      title: 'Dashboard CSAT',
      layout: 'content',
      resource: 'dashboard',
      action: 'read',
    },
  },
  {
    path: '/dashboards/hub',
    name: 'dashboard-hub',
    beforeEnter() {location.href = 'https://app.hubspot.com/reports-dashboard/5261255/view/10755135'},
    meta: {
      title: 'Dashboard Hub',
      layout: 'content',
      resource: 'dashboard',
      action: 'read',
    },
  },
  {
    path: '/dashboards/academy',
    name: 'dashboard-academy',
    component: () => import('@/views/dashboards/academy/CourseDashboard.vue'),
    meta: {
      title: 'Dashboard Academy',
      layout: 'content',
      resource: 'academy',
      action: 'update',
    },
  },
]

export default dashboard
